:root {
  --font-family-base: 'Graphik', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  --font-family-heading: 'Produkt', Georgia, Times, 'Times New Roman', serif, Helvetica;

  --font-weight-regular: 400;
  --font-weight-medium: 600;
  --font-weight-semibold: 700;
  --font-weight-bold: 800;
  --font-weight-black: 1000;

  --line-height-xl: 1.1;
  --line-height-lg: 1;
  --line-height-md: 1.5;
  --line-height-sm: 1.2;

  /* Sizes */
  --font-size-unit: 1px;
  --font-size-viewport-min: 480;
  --font-size-viewport-max: 1920;
  --font-size-growth: calc(1 / (var(--font-size-viewport-max) - var(--font-size-viewport-min)));
  --m: calc((var(--font-size-growth) * 100vw) - (var(--font-size-growth) * var(--font-size-viewport-min) * var(--font-size-unit)));

  --font-size-10-12: clamp(10px, calc(10px + 2 * var(--m)), 12px);
  --font-size-12: 12px;
  --font-size-12-14: clamp(12px, calc(12px + 2 * var(--m)), 14px);
  --font-size-14: 14px;
  --font-size-14-16: clamp(14px, calc(14px + 2 * var(--m)), 16px);
  --font-size-16: 16px;
  --font-size-16-18: clamp(16px, calc(16px + 2 * var(--m)), 18px);
  --font-size-18: 18px;
  --font-size-18-22: clamp(18px, calc(18px + 4 * var(--m)), 22px);
  --font-size-18-24: clamp(18px, calc(18px + 6 * var(--m)), 26px);
  --font-size-22-26: clamp(22px, calc(22px + 4 * var(--m)), 26px);
  --font-size-24: 24px;
  --font-size-24-28: clamp(24px, calc(24px + 4 * var(--m)), 28px);
  --font-size-28-32: clamp(28px, calc(28px + 4 * var(--m)), 32px);
  --font-size-32: 32px;
  --font-size-32-40: clamp(32px, calc(32px + 8 * var(--m)), 40px);
  --font-size-40-52: clamp(40px, calc(40px + 12 * var(--m)), 52px);
  --font-size-40-70: clamp(40px, calc(40px + 30 * var(--m)), 70px);
  --font-size-40-82: clamp(40px, calc(40px + 42 * var(--m)), 82px);
  --font-size-40-240: clamp(60px, calc(60px + 180 * var(--m)), 240px);
  --font-size-100-400: clamp(100px, calc(100px + 300 * var(--m)), 400px);
}
