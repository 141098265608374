:root {
  --color-white: #fff;
  --color-white--20: rgba(255, 255, 255, 0.2);
  --color-white--30: rgba(255, 255, 255, 0.3);
  --color-black: #000;
  --color-text: var(--color-gray-300);
  --color-black-900--30: rgba(10, 10, 10, 0.3);

  --color-gray-100: #e6e6e6;
  --color-gray-300: #333;
  --color-gray-500: #aaa;
  --color-gray-700: #4d4d4d;
  --color-gray-800: #1e1e1e;
  --color-gray-900: #1c1c1c;

  --color-green-200: #aff2d3;
  --color-green-500: #01ac3a;
  --color-green-700: #01982b;

  --color-blue-100: #d2e1ff;
  --color-blue-200: #dceeff;
  --color-blue-300: #90cdfe;
  --color-blue-500: #00f;
  --color-blue-600: #1eadff;
  --color-blue-700: #0000a4;

  /* pastel */
  --color-success: #e5f4e9;
}
