:root {
  --size-4: 4px;
  --size-5: 5px;
  --size-8: 8px;
  --size-10: 10px;
  --size-12: 12px;
  --size-16: 16px;
  --size-18: 18px;
  --size-20: 20px;
  --size-24: 24px;
  --size-32: 32px;
  --size-40: 40px;
  --size-48: 48px;
  --size-56: 56px;
  --size-64: 64px;
  --size-74: 74px;
  --size-96: 96px;
  --size-112: 112px;
  --size-148: 148px;

  --size-header-height-sm: 60px;
  --size-header-height-md: 75px;

  --scroll-offset: 100px;
}

:export {
  headerHeightSm: 60px;
  headerHeightMd: 75px;
}
